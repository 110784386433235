import React from 'react';
import { Route, Switch } from 'react-router';
import Login from '../pages/Login';
import Top from '../pages/Top';
import Apply from "../pages/register/Apply";
import Propose from "../pages/register/Propose";
import Register from "../pages/register/Register";
import RegisterConfirm from "../pages/register/RegisterConfirm";
import WatashiCard from "../pages/watashi/WatashiCard";
import WatashiCardEdit from "../pages/watashi/WatashiCardEdit";
import Schedule from "../pages/schedule/Schedule";
import UketoriList from "../pages/uke/UketoriList";
import UketoriCard from "../pages/uke/UketoriCard";
import UketoriCardMemo from "../pages/uke/UketoriCardMemo";

export const isNoNeedLogins = (location) => {
  return ['/login'].filter((l) => { return l === location }).length > 0
}

const routes = (
  <div>
    <Switch>
      <Route exact path="/" component={Top} />
      <Route path="/login" component={Login} />
      <Route path="/register/propose" component={Propose} />
      <Route path="/register/apply" component={Apply} />
      <Route path="/register/register" component={Register} />
      <Route path="/register/confirm" component={RegisterConfirm} />
      <Route path="/watashi/:id/edit" component={WatashiCardEdit} />
      <Route path="/watashi/:id" component={WatashiCard} />
      <Route path="/schedule" component={Schedule} />
      <Route path="/uketori/:id/memo" component={UketoriCardMemo} />
      <Route path="/uketori/:id" component={UketoriCard} />
      <Route path="/uketori" component={UketoriList} />
    </Switch>
  </div>
)

export default routes;
