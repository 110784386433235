import Layout from "../../layout/Layout";
import globalStyle from "../../styles/Global.scss";
import React from "react";
import {useHistory} from "react-router-dom";

const UketoriList = () => {
  const history = useHistory()

  const toUketoriDetail = (id) => {
    history.push(`/uketori/${id}`)
  }

  return (
    <>
      <Layout category={'UK'} title={'受取名刺'}>
        <div className={globalStyle.container}>
          <div>
            <img onClick={() => toUketoriDetail(1)} alt={'meishi'} className={globalStyle.card} src={'/meishi.png'}/>
          </div>
          <div>
            <img onClick={() => toUketoriDetail(2)} alt={'meishi'} className={globalStyle.card} src={'/meishi.png'}/>
          </div>
          <div>
            <img onClick={() => toUketoriDetail(3)} alt={'meishi'} className={globalStyle.card} src={'/meishi.png'}/>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default UketoriList
