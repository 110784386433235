import Layout from "../../layout/Layout";
import globalStyle from '../../styles/Global.scss'

const Schedule = () => {
  return (
    <>
      <Layout category={'WA'} title={'スケジュール'}>
        <div className={globalStyle.container}>
          スケジュール
        </div>
      </Layout>
    </>
  )
}

export default Schedule
