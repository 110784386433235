import globalStyle from '../../styles/Global.scss';
import style from './Uketori.scss'
import React from "react";
import Layout from "../../layout/Layout";
import {Button} from "react-bootstrap";
import {useHistory, useParams} from "react-router-dom";

const UketoriCard = () => {
  const history = useHistory()
  const {id} = useParams()

  const toBack = () => {
    history.goBack()
  }

  const toMemo = () => {
    history.push(`/uketori/${id}/memo`)
  }

  return (
    <>
      <Layout category={'UK'}>
        <div className={globalStyle.container}>
          <img alt={'meishi'} className={globalStyle.card} src={'/meishi.png'}/>
          <div className={style.dataContainer}>
            <table className={style.table}>
              <tr>
                <th width={'100px'}>
                  <div>会社名/店名</div>
                </th>
                <td width={'200px'}>
                  ○○○○○○○○
                </td>
              </tr>
              <tr>
                <th>
                  <div>所属</div>
                </th>
                <td width={'200px'}>
                  ○○○○○○○○
                </td>
              </tr>
              <tr>
                <th>
                  <div>姓名</div>
                </th>
                <td width={'200px'}>
                  ○○○○○○○○
                </td>
              </tr>
              <tr>
                <th>
                  <div>〒</div>
                </th>
                <td width={'200px'}>
                  ○○○○○○○○
                </td>
              </tr>
              <tr>
                <th>
                  <div>住所</div>
                </th>
                <td width={'200px'}>
                  ○○○○○○○○
                </td>
              </tr>
              <tr>
                <th>
                  <div>TEL/FAX</div>
                </th>
                <td width={'200px'}>
                  ○○○○○○○○
                </td>
              </tr>
            </table>
            <div className={globalStyle.footerContainer}>
              <div className={globalStyle.footer}>
                <div className={globalStyle.centerPanel}>
                  <Button className={style.button} onClick={() => {toMemo()}}>メモ</Button>
                  <Button className={style.button} onClick={() => {toBack()}}>戻る</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default UketoriCard
