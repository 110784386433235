import globalStyle from '../../styles/Global.scss';
import style from './Uketori.scss'
import React from "react";
import Layout from "../../layout/Layout";
import {Button} from "react-bootstrap";
import {useHistory, useParams} from "react-router-dom";

const UketoriCardMemo = () => {
  const history = useHistory()
  const {id} = useParams()

  const toBack = () => {
    history.goBack()
  }

  const toUketoriCard = () => {
    history.goBack()
  }

  return (
    <>
      <Layout category={'UK'}>
        <div className={globalStyle.container}>
          <img alt={'meishi'} className={globalStyle.card} src={'/meishi.png'}/>
          <div className={style.dataContainer}>
            <table className={style.table}>
              <tr>
                <th width={'100px'}>
                  <div>表示順</div>
                </th>
                <td width={'200px'}>
                  <input value={id}/>
                </td>
              </tr>
              <tr>
                <th>
                  <div>受取日</div>
                </th>
                <td width={'200px'}>
                  2022/10/20
                </td>
              </tr>
              <tr>
                <th>
                  <div>メモ</div>
                </th>
                <td width={'200px'}>
                  <input value={'○○○○○○○○○'}/>
                </td>
              </tr>
              <tr>
                <th>
                  <div>評価</div>
                </th>
                <td width={'200px'}>
                  <select>
                    <option></option>
                    <option>☆</option>
                    <option>☆☆</option>
                    <option>☆☆☆</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>
                  <div>評価一覧</div>
                </th>
                <td width={'200px'}>
                  2.4
                </td>
              </tr>
            </table>
            <div className={globalStyle.footerContainer}>
              <div className={globalStyle.footer}>
                <div className={globalStyle.centerPanel}>
                  <Button className={style.button} onClick={() => {toUketoriCard()}}>保存</Button>
                  <Button className={style.button} onClick={() => {toBack()}}>戻る</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default UketoriCardMemo
